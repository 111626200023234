
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap");

.App {
  text-align: center;
}

:root {
  --bannerFont: Montserrat;
  --textFont: Quicksand;
  --clr-neon: #dad3f6;
  --mobileBannerFontSize: font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
}

/* For Mobile Resolutions */
@media (max-width: 1228px){
  .Header {
    background: linear-gradient(180deg, black, #130013);
    padding-top: 1vh;
    padding-bottom: 2vh;
    width: 100vw;
    height: 4vh;
    position: fixed;
    color: white;
    display: flex;
    justify-content: space-between;
  }

  #headerName{
    display: flex;
    align-items: center;
    font-size: 2vmax;
    padding-left: 4vw;
  }

  #buttonContainer{
    display: flex;
    align-items: center;
    padding-top: 1vh;
    padding-right: 5vw;
    gap: 2vw;
  }

  .Buttons{
    font-size: 1.25vmax;
    border: none;
    background-color: transparent;
    color: white;
    text-decoration: none;
  }




  .Front {
    background-color: #130013;
    font-size: calc(10px + 2vmin);
    color: white;

    min-height: 10vh;

    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }




  .EducationBanner {
    display: flex;
    flex-direction: column;
    height: 10vh;
  }

  .EducationBanner .Padding{
    flex: 1;
  }

  .EducationBanner #content{
    flex: 2;
    text-align: center;
    font-family: var(--bannerFont);
    font-size: var(--mobileBannerFontSize);
    letter-spacing: 2px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .Education {
    display: flex;
    flex-direction: column;
    
    padding-bottom: 10vh;
  }

  .Education #lmuPicture {
    flex: 5;

    height: 30vw;
    width: auto;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .Education .TextContainer {
    height: 40vh;
    width: (100 - (1.79 * 35))vw;
    font-family: var(--textFont);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4vh;
  }

  .Education .TextContainer #info{
    flex: 0.2;
    font-size: calc((3vw + 5vh) / 2);
  }

  .Education .TextContainer #relevance{
    flex: 1;
    font-size: calc((1vw + 1.75vh) / 2);
  }

  .Education .TextContainer #relevance1{
    flex: 1;
    padding-top: 2vw;
    padding-left: 3vw;
    padding-right: 3vw;
    text-align: center;
    font-size: calc((1vw + 2.5vh) / 2);
  }

  .Education #buttonContainer{
    padding-left: 3vw;
    flex: 4;
  }

  .EducationButton{
    font-size: 2.5vh;
  }




  .Experience {
    display: flex;
    flex-direction: column;
    height:80vmin;
  }

  .Experience .Top {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--bannerFont);
    font-size: var(--mobileBannerFontSize);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: calc(4vh + 4vw);
  }

  .Experience .Top #title {
    flex: 5;
  }
  
  .Experience .Top #filler {
    flex: 1;
  }

  .Experience .Info {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--textFont);
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: calc(2vw + 5vh);
  }

  .Experience .Info #logo{
    flex: 0.2;
    display: flex;
    justify-content: center
  }
  
  .Experience .Info #logo #icon {
    max-width: calc(5vw + 2vh);
    max-height: calc(5vw + 2vh);
  }

  .Experience .Info #company{
    flex: 0.3;
    font-size: calc(1.4vw + 0.75vh);
  }
  
  .Experience .Info #title {
    flex: 1;
    font-size: calc(1.4vw + 0.75vh);
  }
  
  .Experience .Info #date {
    flex: 0.5;
    font-size: 1.8vw;
  }

  .Experience .Content{
    font-family: var(--textFont);
    font-size: calc((3vw + 1vh) / 2);
    flex: 1;
    padding-left: 10vw;
    padding-right: 10vw;
  }




  .ProjectsBanner{
    padding-top: 10vh;
    padding-bottom: 10vh;
    padding-right: 3vw;
  }

  .ProjectsBanner #title {
    text-align: center;
    font-family: var(--bannerFont);
    font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .Projects {
    height: calc(90vw + 50vh);
    display: flex;
    padding-left: 0vw;
    padding-right: 5vw;
  }

  .Projects .Timeline {
    display: flex;
    flex-direction: column;
    height: 0vh;
    width: 100vw;
    align-items: center;
  }
  
  .Projects .TimelineDates {
    height: 0vw;
    width: 0vw;
  }

  .Projects .TimelineDates .Date {
    height: 0vw;
    width: 0vw;
    font-size: 0vw; 
  }

  .Projects #timelineImageContainer{
    padding: 0;
    width: 80vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Projects #timelineImage{
    max-width: 0vw;
    max-height: 0vh;
  } 

  .Projects .TimelineItems {
    display: flex;
    flex-direction: column;
    width: 80vw;
    gap: 5vw;
  }

  .Projects .TimelineItems .Date {
    font-size: 1vw;
  }

  .Projects .TimelineItems .Item {
    flex: 1;
    align-self: center;
    border: white;
    border-style: solid;
    border-width: 0.11vw;
    font-family: var(--textFont);
    padding: 1vw;
    width: 35vw;
  }

  .Projects .TimelineItems .Item .Title{
    font-size: calc(1.25vw + 1vh);
  }

  .Projects .TimelineItems .Item .Content{
    font-size: calc(1vw + 0.75vh);
  }

  .ProjectsButton {
    font-family: "Rajdhani";
    font-weight: 800;
    font-size: 2vw;
    display: inline-block;
    background-color: transparent;
    border: var(--clr-neon) 0.05em solid;
    border-radius: 0.25em;
    color:white;
  }
  
  .ProjectsButton:hover {
    background-color: var(--clr-neon);
    font-weight: 300;
    transition: background-color 0.2s ease-in;
    color: black;
    box-shadow: inset 0 0 .5em 0 var(--clr-neon), 0 0 .5em 0 var(--clr-neon);
    transition: box-shadow 0.05s ease-in;
    text-shadow: none;
  }

  .Resume{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0cqw;
    padding-bottom: 15vh;
    gap: 2vw;
  }

  .Resume #title {
    height: 10vh;
    width: 40vw;
    text-align: center;
    font-family: var(--bannerFont);
    font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .Resume .ButtonContainer{
    height: 8vh;
    width: 100vw;
  }

  .ResumeButton{
    font-family: "Rajdhani";
    font-weight: 200;
    display: inline-block;
    background-color: transparent;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
    color: var(--clr-neon);
    border: var(--clr-neon) 0.05em solid;
    border-radius: 0.25em;
    font-size:calc(2vw + 1vh);
    align-self: center;
  }

  .ResumeButton:hover {
    background-color: var(--clr-neon);
    font-weight: 300;
    transition: background-color 0.2s ease-in;
    color: black;
    box-shadow: inset 0 0 .5em 0 var(--clr-neon), 0 0 .5em 0 var(--clr-neon);
    transition: box-shadow 0.05s ease-in;
    text-shadow: none;
  }




  .Contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: 70vh;
  }

  .Contact #title{
    height: 10vh;
    width: 40vw;
    text-align: center;
    font-family: var(--bannerFont);
    font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 5vh;
    padding-top: 1vh;
  }

  .Contact .ContactOptions{
    display: flex;
    flex-direction: row;
    width: 80vw;
    gap: 5vw;
  }

  .Contact .ContactFlexContainer{
    flex: 1;
    border: white;
    border-style: solid;
    border-width: 0.11vw;
    display: flex;
    justify-content: center;
  }

  .Contact .ContactButton{
    background-color: transparent;
    width: 23.1vw;
    border-color: transparent;
  }
  
  .Contact .ContactFlexContainer:hover{
    font-weight: 300;
    transition: background-color 0.2s ease-in;
    color: black;
    box-shadow: inset 0 0 .5em 0 var(--clr-neon), 0 0 .5em 0 var(--clr-neon);
    transition: box-shadow 0.05s ease-in;
    text-shadow: none;
  }

  .Contact .ContactContainer{
    height: 14vh;
    align-self: flex-start;
    font-family: var(--textFont);
    color: white;
    padding: 1vw;
    display: flex;
    flex-direction: column;
  }

  .Contact .ContactIcon {
    flex: 5;
    padding-top: 2vh;
  }

  .Contact .ContactPict{
    max-height: 10vw;
    max-width: auto;
  }

  .Contact .ContactText{
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .Contact .ContactTextA{
    font-size: 4vw;
  }
  
  .Contact .ContactTextB{
    font-size: 0vw;
  }
}






/* For Desktop Resolutions */
@media (min-width: 1228px){
  .Header {
    background: linear-gradient(180deg, black, #110011);
    padding-top: 1vh;
    padding-bottom: 2vh;
    width: 100vw;
    height: 7vh;
    position: fixed;
    color: white;
    display: flex;
    justify-content: space-between;
  }

  #headerName{
    display: flex;
    align-items: center;
    padding-left: 3vw;
    font-size: 2vmax;
  }

  #buttonContainer{
    display: flex;
    align-items: center;
    padding-top: 1vh;
    padding-right: 4vw;
  }

  .Buttons{
    padding-right: 2vw;
    font-size: 1.25vmax;
    border: none;
    background-color: transparent;
    color: white;
    text-decoration: none;
  }




  .Front {
    background-color: #110011;
    font-size: calc(10px + 2vmin);
    color: white;

    min-height: 10vh;

    padding-top: 10vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }




  .EducationBanner {
    display: flex;
    flex-direction: column;
    height: 20vh;
  }

  .EducationBanner .Padding{
    flex: 1;
  }

  .EducationBanner #content{
    flex: 2;
    text-align: center;
    font-family: var(--bannerFont);
    font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .Education {
    display: flex;
    flex-direction: row;

    padding-bottom: 20vh;
  }

  .Education .Padding {
    padding: 0.5vw
  }

  .Education #lmuPicture {
    flex: 5;

    /* 1200 * 670 */
    height: 30vw;
    width: auto;
  }

  .Education .TextContainer {
    height: 35vmax;
    width: (100 - (1.79 * 35))vw;
    font-family: var(--textFont);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1vw;
  }

  .Education .TextContainer #info{
    flex: 0.2;
    font-size: calc((5.3vw + 1vh) / 2);
  }

  .Education .TextContainer #relevance{
    flex: 1;
    font-size: calc((1vw + 1.75vh) / 2);
  }

  .Education .TextContainer #relevance1{
    flex: 1.3;
    font-size: calc((2vw + 1vh) / 2);
    padding-right: 1.25vw;  
    padding-left: 1.25vw;
    text-align: justify;
  }
  
  .Education #buttonContainer{
    flex: 3;
    align-self: center;
  }

  .EducationButton{
    font-size: 2vw;
  }




  .Experience {
    display: flex;
    flex-direction: column;
    padding-top: 1vh;
    height:60vh;
  }

  .Experience .Top {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--bannerFont);
    font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .Experience .Top #title {
    flex: 4;
    padding-bottom: 10vh;
  }
  
  .Experience .Top #filler {
    flex: 5;
  }

  .Experience .Info {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--textFont);
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .Experience .Info #logo{
    flex: 0.2;
    display: flex;
    justify-content: center
  }
  
  .Experience .Info #logo #icon {
    max-width: 10vh;
    max-height: 10vh;
  }

  .Experience .Info #company{
    flex: 0.3;
    font-size: 2vw;
  }
  
  .Experience .Info #title {
    flex: 1;
    font-size: 2vw;
  }
  
  .Experience .Info #date {
    flex: 0.5;
    font-size: 1.5vw;
  }

  .Experience .Content{
    font-family: var(--textFont);
    font-size: 1.5vw;
    flex: 1;
    padding-left: 10vw;
    padding-right: 10vw;
  }




  .ProjectsBanner{
    padding-top: 30vh;
    padding-bottom: 15vh;
  }

  .ProjectsBanner #title {
    text-align: center;
    font-family: var(--bannerFont);
    font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .Projects {
    height: 100vh;
    display: flex;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .Projects .Timeline {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    align-items: center;
  }
  
  .Projects .TimelineDates {
    display: flex;
    flex-direction: row;
    width: 80vw;
    align-self: center;
  }

  .Projects .TimelineDates .Date {
    flex: 1;
    align-self: center;
  }

  .Projects #timelineImageContainer{
    padding: 0;
    width: 80vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Projects #timelineImage{
    flex-grow: 1;
    max-width: 80vw;
    max-height: 100vh;
  } 

  .Projects .TimelineItems {
    display: flex;
    flex-direction: row;
    width: 80vw;
    gap: 1vw;
  }

  .Projects .TimelineItems .Date {
    font-size: 1vw;
  }

  .Projects .TimelineItems .Item {
    flex: 1;
    align-self: flex-start;
    border: white;
    border-style: solid;
    border-width: 0.11vw;
    font-family: var(--textFont);
    padding: 1vw;
  }

  .Projects .TimelineItems .Item .Title{
    font-size: 1.5vw;
  }

  .Projects .TimelineItems .Item .Content{
    font-size: 1.2vw;
  }

  .ProjectsButton {
    font-family: "Rajdhani";
    font-weight: 800;
    display: inline-block;
    background-color: transparent;
    border: var(--clr-neon) 0.05em solid;
    border-radius: 0.25em;
    color:white;
  }
  
  .ProjectsButton:hover {
    background-color: var(--clr-neon);
    font-weight: 300;
    transition: background-color 0.2s ease-in;
    color: black;
    box-shadow: inset 0 0 .5em 0 var(--clr-neon), 0 0 .5em 0 var(--clr-neon);
    transition: box-shadow 0.05s ease-in;
    text-shadow: none;
  }



  .Resume{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 35vh;
    gap: 2vw;
  }

  .Resume #title {
    height: 10vh;
    width: 40vw;
    text-align: center;
    font-family: var(--bannerFont);
    font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .Resume .ButtonContainer{
    height: 8vh;
    width: 15vw;
  }

  .ResumeButton{
    font-family: "Rajdhani";
    font-weight: 200;
    display: inline-block;
    background-color: transparent;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
    color: var(--clr-neon);
    border: var(--clr-neon) 0.05em solid;
    border-radius: 0.25em;
    font-size: 1.5vw;
  }

  .ResumeButton:hover {
    background-color: var(--clr-neon);
    font-weight: 300;
    transition: background-color 0.2s ease-in;
    color: black;
    box-shadow: inset 0 0 .5em 0 var(--clr-neon), 0 0 .5em 0 var(--clr-neon);
    transition: box-shadow 0.05s ease-in;
    text-shadow: none;
  }

  


  .Contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: 42vh;
  }

  .Contact #title{
    height: 10vh;
    width: 40vw;
    text-align: center;
    font-family: var(--bannerFont);
    font-size: calc(100% + 3 * (100vw - 1000px) / 1000);
    letter-spacing: 4px;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 10vh;
    padding-top: 1vh;
  }

  .Contact .ContactOptions{
    display: flex;
    flex-direction: row;
    width: 80vw;
    gap: 5vw;
  }

  .Contact .ContactFlexContainer{
    flex: 1;
    border: white;
    border-style: solid;
    border-width: 0.11vw;
    display: flex;
    justify-content: center;
  }

  .Contact .ContactButton{
    background-color: transparent;
    width: 23.1vw;
    border-color: transparent;
  }
  
  .Contact .ContactFlexContainer:hover{
    font-weight: 300;
    transition: background-color 0.2s ease-in;
    color: black;
    box-shadow: inset 0 0 .5em 0 var(--clr-neon), 0 0 .5em 0 var(--clr-neon);
    transition: box-shadow 0.05s ease-in;
    text-shadow: none;
  }

  .Contact .ContactContainer{
    height: 14vh;
    align-self: flex-start;
    font-family: var(--textFont);
    color: white;
    padding: 1vw;
    display: flex;
    flex-direction: row;
  }

  .Contact .ContactIcon {
    flex: 0.3;
  }

  .Contact .ContactPict{
    max-height: 4vw;
    max-height: auto;
  }

  .Contact .ContactText{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .Contact .ContactTextA{
    font-size: 1.5vw;
  }
  
  .Contact .ContactTextB{
    font-size: 1vw;
  }
}




.EducationButton {
  font-family: "Rajdhani";
  font-weight: 200;
  display: inline-block;
  background-color: transparent;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  color: var(--clr-neon);
  border: var(--clr-neon) 0.05em solid;
  border-radius: 0.25em;

  /* text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.125em currentColor; */

  /* box-shadow: inset 0 0 0.15em 0 var(--clr-neon), 0 0 0.15em 0 var(--clr-neon); */
}

.EducationButton:hover {
  background-color: var(--clr-neon);
  font-weight: 300;
  transition: background-color 0.2s ease-in;
  color: black;
  box-shadow: inset 0 0 .5em 0 var(--clr-neon), 0 0 .5em 0 var(--clr-neon);
  transition: box-shadow 0.05s ease-in;
  text-shadow: none;
}


/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
Code that basically creates an animation for media.
Example code for usage:
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

Note: @media is not required but it is used for some reason
*/
